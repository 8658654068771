.headtitle {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  margin-bottom: 10px;
  margin-top: 10px;
}
.urgent {
  background-color: #ffeaea;
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  color: #c24c4c;
}
.gap {
  column-gap: 15px;
  margin-top: 10px;
}
.assist {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  margin-top: 10px;
}
.disassign {
  width: 200px;
  border-radius: 10px;
  height: 48px;
  border: none;
  color: #dfe0e0;
  margin-bottom: 10px;
}
.assign {
  width: 200px;
  border-radius: 10px;
  height: 48px;
  border: none;
  color: #dfe0e0;
  background-color: #0c344e;
  color: white;
  margin-bottom: 10px;
}
.paros {
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.2);
  margin: 0px;
  padding: 0px 8px 0px 8px;
  border-radius: 4px;
  width: 450px;
}
.handle-Shadow {
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.2);
  padding: 0px 16px;
  border-radius: 4px;
  height: 40px;
}
.description {
  display: flex;
  align-items: flex-end;
  column-gap: 20px;
}
.prbtn {
  width: 120px;
  height: 48px;
  border-radius: 12px;
  border: 2px solid #d9dbe9;
}
.f-bold {
  font-weight: bold;
}
.staffdetails {
  border: 1px solid black;
  padding: 10px;
  border-radius: 5px;
}
.Pending {
  color: #d65400;
}
.Assigned {
  color: Blue;
}
.In-Process {
  color: Orange;
}
.Completed {
  color: green;
}
.Cancelled {
  color: grey;
}
.documentpdf {
  width: 1010px;
  height: 190px;
  background: #ffffff;
  border: 1px solid #dfe0e0;
  border-radius: 12px;
  margin-top: 20px;
  padding: 10px 10px 10px 25px;
  display: flex;
  column-gap: 10px;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  margin-bottom: 1rem;
  overflow-y: auto;
}
.statusflow {
  width: 1010px;
  height: 350px;
  background: #ffffff;
  border: 1px solid #dfe0e0;
  border-radius: 12px;
  display: flex;
  justify-content: start;
  gap: 25px;
}
.visiblerequestimg {
  display: flex !important;
  column-gap: 10px !important;
  flex-wrap: wrap !important;
}
.assign-staff {
  width: 1010px;
  border-radius: 12px;
  border: 1px solid #dfe0e0;
  display: grid !important;
  grid-template-columns: 65% 33%;
}
.staffpart {
  width: 100%;
  padding: 20px 0px 10px 30px;
}
.timelineScroll {
  width: 330px;
  height: 100%;

  background-color: #fff7f3;
  overflow-y: auto;
}
.statustitle {
  display: flex;
  justify-content: space-between;
  margin: 16px 12px 1px 12px;
}
/* .growDailoc .MuiPaper-root{
    max-width: unset !important;
    padding: 0px 30px ;
    width: 25rem;
} */

.details-dialog .MuiPaper-root {
  max-width: unset;
  padding: 0.5rem;
  overflow-x: auto;
}
.slotshowonselect {
  display: flex;
  justify-content: flex-start;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
  padding-top: 15px;
}
.timeproper {
  width: 170px;
  height: 40px;
  background-color: #eff0f6;
  color: #0c344e;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
}
.addBackOnselect {
  width: 170px;
  height: 40px;
  background-color: #eff0f6;
  color: #0c344e;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: #0c344e;
  color: white;
}
.selectedStaff {
  box-shadow: 0 0px 4px 0 rgb(0 0 0 / 20%);
  width: 545px;
  height: 55px;
  padding-left: 12px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.changecolor fieldset {
  border: none !important;
  border-color: none !important;
  border-bottom: 1.3px solid black !important;
  border-radius: 0px;
}
.changecolor label {
  color: #727272 !important;
  font-size: 15px;
  padding: 8px 0px 5px 0px !important;
  margin-left: -12px !important;
}

.handledrop li {
  display: flex !important;
  align-items: flex-start !important;
}

.changecolor .MuiInputBase-input {
  padding: 16.5px 0px 10px 4px;
}
.inputopacity .MuiSelect-nativeInput {
  opacity: 1 !important;
  border: none;
  border-bottom: 1px solid black;
}
.timeEditHanlde fieldset {
  border-width: none !important;
  border: none !important;
  border-bottom: 1px solid black !important;
  border-radius: 0px;
  outline: none;
}
.timeEditHanlde {
  width: 222px !important;
}
/* .editAddstaffAssign > button{
    border: none;
    padding: 10px 25px;
    border-radius: 5px;
    background: transparent;
    border: 1px solid black;

} */

.alternativeUpdoad {
  width: 300px;
  height: 50px;
  background: #ffffff;
  box-shadow: 0px 3px 9px rgba(112, 112, 112, 0.22);
  border-radius: 4px;
  padding: 10px 15px 0px 15px;
  margin-top: 5px;
}
.attchmentcard {
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0px 4px 0 rgb(0 0 0 / 20%);
  padding: 0px 16px;
  border-radius: 4px;
  height: 40px;
  margin-bottom: 1rem;
}
.attchmenttile {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
}
.addNewStaff {
  padding: 8px 150px;
  border-radius: 10px;
  color: black;
  background-color: transparent;
  background: #0c344e;
  color: white;
  border: none;
}
.changeStatus {
  width: 200px;
  height: 50px;
  border-radius: 10px !important;
  text-transform: capitalize !important;
}
.changeStatus:not(:disabled) {
  background-color: #0c344e !important;
  color: white;
  text-transform: capitalize;
}
