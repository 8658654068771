.Cinvoice{
    width:110px;
    height:48px;
    background-color: white;
    border-radius: 15px;  
}
.individual{
    width: 110px;
    height: 40px;
    background-color: white;
    border:1px solid #0C344E;
    border-radius: 19px;
    font-weight: 500;
    
}
.individual-btn-active{
        width: 110px;
        height: 40px;
        background-color:#0C344E;
        border: 1px solid #0C344E;
        border-radius: 19px;
        font-weight: 500;
        color: white;
}

.bulk{
    width: 90px;
    height: 40px;
    border-radius: 19px;
    border: 1px solid #0C344E;
    font-weight: 500;
    background-color: #0C344E;
    color: white;

}
.bulk-btn-active {
    width: 90px;
    height: 40px;
    border-radius: 19px;
    border: 1px solid #0C344E;
    font-weight: 500;
    background-color:white;
    color: #0C344E;
}
.line{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 550px;
    height: 3px;
    background-color:#D9DBE9;
    border-radius:15px;
}
.Sinvoice{
    width: 578px;
}
.Radd{
    width:295px;
    height: 64px;
    border: 1px dotted #DFE0E0;
}
.rform{
    width:600px;

}
.Ginvoicebtn{
    width: 278px;
    height: 48px;
    border: 1px solid #DFE0E0;
    background-color: #0C344E;
    color: white;
    border-radius: 15px;
    font-weight: 500;
    text-align: center;
    margin-top: 10px;
    
}
.Gsection{
    width: 580px;
    display: flex;
    justify-content: flex-end;
}