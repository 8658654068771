.editedtail{
    width: 120px;
    height:48px;
    background-color: white;
    border-radius: 15px;
    border: 1px solid #0C344E;
    font-weight: 600;
    margin-right: 20px;
}
.Dpaynow{
        width: 120px;
        height:48px;
        background-color: #0C344E;
        border-radius: 15px;
        border: 1px solid #0C344E;
        color: white;
        font-weight: 600;
}
.Edetailform{
    width: 700px;
}
.swift{
    width: 600px;
}

.pdue{
    width: 200px;
    height:28px;
    background-color:#FFE3D2;
    text-align: center;
    border-radius: 15px;
    color: #D65400;
}
.pmodel{
    width: 550px;
}

.plater{
    width:250px;
    height: 48px;
    background-color: white;
    border-radius: 15px;
    border:1px solid #0C344E;
}
.pnow{
        width: 250px;
        height: 48px;
        background-color:#0C344E;
        border-radius: 15px;
        color: white;
}
.pexpenses{
    width: 170px;
    height: 35px;
    background-color: white;
    border: 1px solid #0C344E;
    border-radius: 15px;
    font-weight: 600;
}
.uexpenses{
        width: 150px;
        height: 35px;
        background-color:#0C344E;
        border: 1px solid #0C344E;
        color: white;
        border-radius: 15px;
        font-weight: 600;
}

.uexpenses:disabled {
    opacity: 0.2;
}
