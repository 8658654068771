.imageContainer {
  position: relative;
}
.mainImage {
  width: 200px;
  height: 135px;
  object-fit: cover;
}
.imageDel {
  position: absolute;
  background-color: rgb(65, 63, 66);
  color: lightgray;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.5s ease;

  top: 0;
  right: 0;
}
.imageDel:hover {
  transform: scale(1.1);
}
