.Property {
    display: flex;
    flex-direction: row;
    font-size: 20px;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-weight: 600;
    font-family: 'Barlow',
        sans-serif;
    line-height: 24px;
    align-items: center;

}
.csvExport{
    text-decoration: none;
    color: black;
    border: 2px solid black;
    width: 150px;
    text-align: center;
    height: 48px;
    border-radius: 5px;
    margin-left: 20px !important;
}
/* .home{
    min-height: 600px;
} */