.thead {
  background-color: #fff7f3;
  width: 100%;
}

.bold {
  font-weight: bold;
}
.bgurgent {
  background-color: #ffeaea;
  padding: 5px 10px;
  border-radius: 10px;
  color: #c24c4c;
}
.bgnormal {
  background-color: #fff7f3;
  padding: 5px 10px;
  border-radius: 10px;
  color: #bdbdbd;
}
.Sbold {
  font-weight: 700 !important;
  white-space: nowrap !important;
  
}
.c-btn {
  background: none;
  border: none;
  width: 200px;
  font-size: 15px;
}
.makespace {
  /* width: 180px !important; */
  white-space: nowrap !important;
  
}
.css-1yhpg23-MuiTableCell-root {
  white-space: nowrap;
}
.nofound {
  color: black;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.generateId {
  color: #105057 !important;
}
.startDate {
  padding: 8px 10px;
  border-radius: 5px;
  height: 45px;
}
.search {
  padding: 8px 13px;
  background-color: white;
  border-radius: 5px;
}
.searchunit {
  border: 1px solid black;
}
.handleloader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  /* transform: translate(-50%, -70%); */
}
[type="search"] {
  outline-offset: 0px;
}
.fadeStatus {
  color: #bdbdbd;
}
.pending {
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  color: #c24c4c;
  background-color: #ffe3d2;
  color: #d65400;
}

.assinged {
  padding: 5px 8px;
  border-radius: 5px;
  margin-bottom: 10px;
  color: #fe8b02;

  background-color: #feffd2;
}
.Cancelledlist {
  padding: 5px 8px;
  border-radius: 5px;
  margin-bottom: 10px;
  color: #f5f5dc;
  background-color: #808080;
}
.re-open {
  padding: 5px 8px;
  border-radius: 5px;
  margin-bottom: 10px;
  color: white;
  background-color: #00008b;
}
.On-hold {
  padding: 5px 8px;
  border-radius: 5px;
  margin-bottom: 10px;
  color: #c24c4c;
  background-color: #ffeaea;
}

.inprocess {
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  color: #c24c4c;
  background-color: #eff0f6;
  color: #0c344e;
}

.success {
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  color: #c24c4c;
  background-color: #d7ffe9;
  color: green;
}


