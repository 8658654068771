
.image-upload{
    display:flex;
    flex-direction: column;
    border:1px dashed #DFE0E0;
    width: 200px;
    height: 137px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
    margin-left: 8px;

}
.Addingimage{
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 9px;
    opacity: 0.8;
}