.wrapper{
    background-color: rgb(246, 251, 244);
    border-radius: 10px;
    height: 250px;
   
    display: flex;
    margin: 1rem;
    justify-content: center;
   
}
.container{
    display: flex;
    flex-direction: column;
    height: 86vh !important;
    overflow: auto;
    align-items: center;
    padding: 1rem;
  
}
.top{
    position: relative;
}

.bottomOption{
    width: 200px;
}
.buttonSave{
    width: 212px;
    border: none;
    border-radius: 10px;
    font-size: 15px;
    cursor: pointer;
    background-color: rgb(6, 40, 61);
    margin-top: 1rem;
    color: white;
}
.addDocButton{
    border: 1px dotted rgb(214, 213, 168);
    border-radius: 10px;
    opacity: 0.8;
    font-weight: 600;
    width: 200px;
    height: 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.closeButton{
    margin-left: 5px;
    cursor: pointer;
    border-radius: 50%;
    background-color: lightgray;
    transition: all 0.5s ease;
    opacity: 0.5;
    position: absolute;
    top: 0;
    right: 0;
}
.closeButton:hover{
    transform: scale(1.2);
    opacity: 1;
}
