.mainBox{
    text-align: center;
    /* background-color: red; */
    height: 5rem;
    padding: 1rem;
    color: #727272;
}
.boxSideSlot{
    text-align: center;
    padding: 1rem;
    cursor: pointer;
}

.boxSlot{
    text-align: center;
    padding: 1rem;
    border-top: 1px dashed #D9DBE9;
    border-bottom: 1px dashed #D9DBE9;
    border-right: 0.5px solid #D9DBE9;
    border-left: 0.5px solid #D9DBE9;
    cursor: pointer;
    width: auto;
    height: 4rem;
}
.dateField .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border: 1px solid black !important;  
  }