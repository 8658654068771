.post{
    width: 460px;
    min-height: 830px;
    margin-bottom: 10px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    
}
.round:hover{
   background-color: #0C344E;
   color: white; 
}
.round{
    border-radius: 34px;
    padding: 2px 20px;
    background-color:transparent;
    color: #0C344E;
    
}
.change{
    background-color: #EFF0F6;
    color:#0C344E;
    border-radius: 8px;
    padding: 2px 20px;
    
}
.pheader{
        width: 460px;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius:5px 5px 0px 0px;
        background-color: #fff;
        box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.2);
}
.announce{
    background-color: #EFF0F6;
    color:#0C344E;
    border-radius: 8px;
}
.paros{
    box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.2) ;
    margin: 0px;
    padding: 0px 8px 0px 8px;
    border-radius: 4px;
}
.select{
    border-top-left-radius: 50px;
}
.reportedpost{
    width: 460px;
    height:670px;
    margin-bottom: 10px;
    padding: 20px;
     border: 1px solid #ccc; 
     border-radius: 5px; 
     background-color: #fff; 
     box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.2);

}
.name{
    width: 300px;
    font-size: 13px;
    font-weight: bold;
    color: #0C344E;
    margin-bottom: 0px;
}
