body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */



.timeslotD{
  color: #727272;
}
.showSlot{
  width: 98%;
  border: 1px solid #727272;
  padding: 0px !important;
  /* padding: 1rem; */
  border-radius: 10px;
  /* border: 1px solid #c4c4c4; */
  border-style: none;

}
.slotshow{
  width: 200px;
  height: 40px;
  display: flex;
  border-radius: 5px;
  padding: 0px 10px 0px 10px;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  border: 1px solid #727272;
  cursor: pointer;

}
/* .react-calendar {
  border-radius: 1rem !important;
} */
.slotrecog{
  margin-left: 8px;
  color: #7a6666;
  font-size: 18px;
}
.activeS{
  border-bottom: 2px solid #006edc;
}

.selectedslotShow{
  width:"120px";
  height:"100px";
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
  margin-top: 20px;

}
.dateselected{
  background-color: #0c344e;
  color: #fff ;
  width: 150px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.timeselected{
  background-color: #0c344e;
  color: #fff;
  width: 165px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.onEffect{

      width: 200px;
      height: 40px;
      display: flex;
      border-radius: 5px;
        padding: 0px 10px 0px 10px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-top: 10px;
      border: 1px solid #727272;
      background-color: #0C344E;
      color: #fff;
    
}
.activeToggleButton{
  width: 8.5rem !important;
  height: 2.5rem !important
}
.react-calendar__tile--active {
  border-radius: 5px !important;
}
.dateColor .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border: 1px solid black !important;  
}
.dateFeild.css-lqdstg .MuiTextField-root {
  width: 100% !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
    color: #7a6666 !important;
}
.makeAnchor{
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
.BtnsaveRequest:hover{
  background-color: #0C344E !important;
  color: white !important;
}
::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;
  background-color: black;
} 

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 1rem;
  width: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.hiddenScroll::-webkit-scrollbar {
  display: none !important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0 !important; 
}

.inputopacity .MuiSelect-nativeInput {
  opacity: 1 !important;
  border: none;
  border-bottom: 1px solid black;
}