
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.logo {
    width: 140px;
    height: 130px;
    margin-top: -28px;
    margin-left: 20px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.TopBar {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px;

  background: #0c344e;
  border-bottom-right-radius: 20px;
  height: 50px;
}

.TopBar-child {
  display: flex;
}

.Top {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.Notification {
  width: 24px;
  height: 24px;
  align-items: center;
}

.LeftBar {
  display: flex;
  flex-direction: column;

  width: fit-content;
  height: fit-content;
  background: #ffe3d2;
}

.feature {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  font-weight: 600;
  font-family: "Barlow", sans-serif;
  background: #0c344e;
}

.feature_Sr {
  margin-top: 1rem;
  /* margin-left: 2.5rem; */
  font-size: 1rem;
  font-weight: 600;
  font-family: "Barlow", sans-serif;
  background: #ffe3d2;
  color: #0c344e !important;
}

.accordion-header > .accordion-button {
  background-color: #0c344e;
  border-color: none;
  color: "#fff" !important;
}

.handlespacenav > .accordion-button {
  padding-top: 0px;
}

.accordion-header > .elements {
  background-color: #ffe3d2;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.accordion-collapse {
  background-color: #0c344e;
}

ul {
  left: 0px;
}

.navDev ul:hover {
  background-color: #f79e34;
  /* color: #fff; */
}

.navDev ul:active {
  background-color: #f79e34;
  /* color: #fff; */
}

.uniqueactive {
  background-color: #f79e34;
  color: #fff !important;
}

.feature.button {
  background: black;
}

.upper {
  background-color: #ffe3d2;
  color: #0c344e;
}

.home {
  padding: 44px 10px 40px 40px;
  width: 80vw;
  height: 86vh;
  border-top-left-radius: 30px;
  background: #efefef;
  overflow: auto;
}
.home::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;
  background-color: black;
}

/* Track */
.home::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.home::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 1rem;
  width: 10px;
}

/* Handle on hover */
.home::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.container {
  max-width: 600px;
  /* margin: 6rem auto 4rem; */
  padding: 0 10px;
}

.heading {
  font-size: 23px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.accordion {
  list-style: none;
}

.accordion_item {
  border-top: 1px solid #9f9f9f;
}

.button {
  font-size: 16px;
  background-color: #008593;
  color: #ffe3d2;
  text-align: left;
  font-weight: 700;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 18px 8px;
  cursor: pointer;
  border: none;
}

.control {
  font-size: 20px;
}

.answer {
  background-color: #f7f7f7;
  padding: 20px;
}

/* activate toggle */
.accordion_item.active .button {
  background-color: #105057;
}

.answer_wrapper {
  height: 0;
  overflow: hidden;
  transition: height ease 0.2s;
}

/* .answer_wrapper.open {
  height: auto;
} */

.elements {
  padding: 5px 0px 5px 0px !important;
}

.navitem {
  padding: 10px 50px !important;
  font-weight: 500;
}

.upper button:focus {
  box-shadow: none;
}

.upper {
  border-width: 0px !important;
}

.header {
  width: 20vw;
  background-color: #0c344e;
  height: 86vh;
  overflow: auto;
}
.header::-webkit-scrollbar {
  width: 0px;
}

.navlink {
  color: black;
  text-decoration: none;
}

.l-dashboard {
  text-decoration: none;
  color: black;
}
.urgent {
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  color: #c24c4c;
  background-color: #ffe3d2;
  color: #d65400;
}

.normal {
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  color: #c24c4c;
  background-color: #eff0f6;
  color: black;
}

.top-level-container {
  background: #0c344e;
  display: flex;
  min-height: 86vh;
}

.logoutButton {
  background-color: #0c344e;
  color: #0c344e;
  border: none;
  font-family: "Barlow";
  font-size: "1rem";
}

.fontManage input {
  font-size: 15px !important;
}

.floorCatagory {
  color: #0c344e !important;
  background-color: #eff0f6;
  padding: 5px 13px;
  border-radius: 5px;
}

/* .MuiPaginationItem-page {
  background: #FFE3D2 !important;
  color:#0C344E !important;
} */

.Mui-selected {
  background-color: #0c344e !important;
  color: #fff !important;
}

* {
  font-family: "Barlow", sans-serif !important;
  font-weight: 600 !important;
}

.logoutContainer {
  padding: 40px 50px 40px 50px;
  background: #ffffff;
  box-shadow: 0px -2px 20px rgba(0, 0, 0, 0.23);
  border-radius: 16px;
}

.logoutHeading {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 19px;
  text-align: center;

  color: #111120;
}

.logoutText {
  margin-top: 20px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.3px;
  color: #111120;
}

.noBtn {
  outline: none;
  border: none;
  padding: 10px 50px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.3px;
  color: #0c344e;
}

.yesBtn {
  padding: 10px 50px;
  text-align: center;
  outline: none;
  border: #0c344e;
  background: #0c344e;
  border-radius: 12px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.3px;
  color: #fcfcfc;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  box-shadow: 0px -2px 20px rgba(0, 0, 0, 0.23) !important;
  border-radius: 16px !important;
}

.accordion-button:not(.collapsed) {
  color: black !important;
  background-color: #0c344e !important;
  box-shadow: inset 0 0px 0 rgb(0 0 0 / 0%) !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url(./assets/downarror.png) !important;
}
.accordion-button::after {
  background-image: url(./assets/downarror.png) !important;
  background-size: "300px 300px";
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  min-height: fit-content !important;
  max-height: 170px;
}

.colorSelected {
  background-color: yellow;
}

.typestatus {
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  color: #c24c4c;
  background-color: #eff0f6;
  color: #0c344e;
}

.requestcard {
  border: 1px solid #dfe0e0;
  border-radius: 12px;
  min-width: 443px;
  min-height: 355px;
  padding: 32px;
}

.requestcatagorycard {
  border: 1px solid #dfe0e0;
  border-radius: 12px;
  width: 550px;
  min-height: 355px;
  padding: 32px;
}
.hanldeColor.MuiButtonBase-root {
  background-color: #0c344e !important;
  color: white;
  text-transform: capitalize;
}
.pending {
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  color: #c24c4c;
  background-color: #ffe3d2;
  color: #d65400;
}

.assinged {
  padding: 5px 8px;
  border-radius: 5px;
  margin-bottom: 10px;
  color: #fe8b02;

  background-color: #feffd2;
}
.Cancelledlist {
  padding: 5px 8px;
  border-radius: 5px;
  margin-bottom: 10px;
  color: #f5f5dc;
  background-color: #808080;
}
.re-open {
  padding: 5px 8px;
  border-radius: 5px;
  margin-bottom: 10px;
  color: white;
  background-color: #00008b;
}
.On-hold {
  padding: 5px 8px;
  border-radius: 5px;
  margin-bottom: 10px;
  color: #c24c4c;
  background-color: #ffeaea;
}

.inprocess {
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  color: #c24c4c;
  background-color: #eff0f6;
  color: #0c344e;
}

.success {
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  color: #c24c4c;
  background-color: #d7ffe9;
  color: green;
}
.searchIcon {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.rowTable:nth-child(even) {
  background: #f7f7f7;
}

/* .css-11xur9t-MuiPaper-root-MuiTableContainer-root::-webkit-scrollbar-thumb {
  min-height: 40px;
  background-color: red;
} */

th {
  background-color: #0c344e !important;
  color: white !important;
}
.backbtn {
  width: 120px;
  padding: 7px 15px;
  background-color: #efefef;
  border-radius: 10px;
  color: black;
  text-decoration: none;
  margin-bottom: 4px;
}
.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  max-height: 200px !important;
}

.customiseCountryCode .MuiSelect-nativeInput {
  opacity: 1;
}

.slick-arrow::before {
  display: none;
}
.slick-arrow.slick-next {
  transform: scale(1.5);
  right: 0;
}
.slick-arrow.slick-prev {
  transform: scale(1.5);
  left: 0;
  z-index: 1;
}

.countryCodeCus .MuiSelect-nativeInput {
  opacity: 1;
}
.MuiMenu-paper {
  max-height: 200px !important;
}
.handleOpacity .PrivateSwitchBase-input {
  opacity: 1 !important;
}

.handleContent > p {
  width: 500px;
}
.handleContent > p > img {
  width: 500px;
  height: 300px;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner-container{
  position: absolute;
  left: 57vw;
  top: 20rem;
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #fb486a; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
.inputopacity .MuiSelect-nativeInput {
  opacity: 1 !important;
  border: none;
  border-bottom: 1px solid black;
}
