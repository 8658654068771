.image-details {
  display: flex;
  height: 135px;
  margin-top: 44px;
}
.image-details > :not(:last-child) {
  margin-right: 16px;
}
.image-upload {
  display: flex;
  flex-direction: column;
  border: 1px dashed #dfe0e0;
  width: 200px;
  height: 137px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
}
.Addingimage {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 9px;
  opacity: 0.8;
}
