.submit{
   width: 100px;
   height: 46px;
   background-color: white;
   border-radius: 5px;

}

.form{
    height: 120px;
    
}