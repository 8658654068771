.frequencyBtn::hover{
    color: #fff !important;
    background-color: #7e1946 !important;
    margin-right: 1rem !important;
  }

  .frequencyBtnActive{
    color: #fff !important;
    background-color: #7e1946 !important;
    margin-right: 1rem !important;
  }
  .frequencyBtn{
    color: #7e1946 !important;
    background-color: #fff !important;
    margin-right: 1rem !important;
  }
  