@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.toplogin{
    min-height: 100vh;
    background-color: #FFE3D2;
}

.login{
   
    
    display: flex;
    justify-content: center;
    align-items: center;
    
    
}
.login-logo{
    padding: 1rem;

}

/* .App {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 1rem 0.5rem;
} */

.down-login {
    width: 100%;
    max-width: 460px;
    min-height: 580px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
    background-color: rgba(87, 86, 86, 0.4);
    border-bottom-left-radius: 2rem;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    z-index:55;
    
}

 form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-grow: 1;
  
} 

/* a,
a:visited {
    color: #fff;
} */

/* input[type="text"],
input[type="password"],
button,
textarea {
    font-size: 22px;
    padding: 0.25rem;
    border-radius: 0.5rem;
} */

login-label{
    font-size: 2rem;
}
login-button {
    margin-top: 1rem;
    font-size: 2rem;
}

button {
    padding: 0.5rem;
}


.instructions {
    font-size: 1rem;
    border-radius: 0.5rem;
    background: #000;
    color: #fff;
    padding: 0.25rem;
    position: relative;
    bottom: -10px;
}

.instructions>svg {
    margin-right: 0.25rem;
}

.offscreen {
    position: absolute;
    left: -9999px;
}

.hide {
    display: none;
}

.valid {
    color: limegreen;
    margin-left: 0.25rem;
}

.invalid {
    color: red;
    margin-left: 0.25rem;
}

.errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.line {
    display: inline-block;
} 

.password-toggle-icon{
    position: relative;
    bottom: 65px;
    left: 375px;
    z-index: 1000;
}
.password-toggle-icon :hover{
    cursor: pointer;
}