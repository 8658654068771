.docWrapper{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}
.documentType{
    font-size: 20px;
    font-weight: 600;
    color: rgb(6, 40, 61);
    opacity: 0.8;
}
.documentName{
    width: 220px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    color: rgb(6, 40, 61);
    opacity: 0.8;
    box-shadow: 3px 4px 10px 1px rgba(0,0,0,0.75);
}
.pdf{
    margin-right: 5px;
    color: rgb(243, 120, 120);
}
.delDoc{
    cursor: pointer;
    /* border: 1px solid black; */
    border-radius: 50%;
    margin-left: 10px;
    background-color: rgb(56, 56, 56);
    color: lightgray;
    opacity: 0.5;
    transition: all 0.5s ease;
}
.delDoc:hover{
    transform: scale(1.1);
    opacity: 1;
}