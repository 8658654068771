.dialogRed {
  background: #f0f0f0;
  color: white;
  padding: 10px;
  background-color: #0c344e;

  border: none;
  cursor: pointer;
  border-radius: 5px;
  width: 100px;
}
.dialogGreen {
  background: #121212;
  color: #0c344e;
  padding: 10px;
  width: 100px;
  background: rgb(240, 240, 240);
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.applicableMode .MuiSelect-select {
  padding-right: 200px !important;
}
.DropCurrency .MuiSelect-select {
  padding-right: 215px !important;
}
