.Property {
    display: flex;
    flex-direction: row;
    font-size: 20px;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-weight: 600;
    font-family: 'Barlow',
        sans-serif;
    line-height: 24px;
    align-items: center;

}
.visitorinput{
    width: 500px !important;
}
.frame{
    width: 200px;
    height: 150px;
    margin-top: 30px;
}





