

* {
    margin: 0;
    padding: 0;
}



/* Timeline Container */
.timeline {
    background: var(--primary-color);
    margin: 0px auto;
    padding: 20px;
}

/* Outer Layer with the timeline border */
.outer {
    border-left: 2px solid #BDBDBD;
}

/* Card container */
.card-title {
    position: relative;
    margin: 0 0 20px 20px;
    padding: 10px;
    background-color: right !important;
    color: gray;
    border-radius: 8px;
    max-width: 400px;
}

/* Information about the timeline */
.info {
    display: flex;
    flex-direction: column;
    
    
}
.info > p{
    margin-bottom: 0px !important;
    color: #111120;
    width: 260px;
    font-size: 13px;
}

/* Title of the card */
.title {
    color: #727272;
    position: relative;
}

/* Timeline dot  */
.title::before {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    background:#0C344E;
    border-radius: 999px;
    left: -38.6px;
    border: 3px solid #0C344E;
    
    
}
.Pending{
  color: #D65400;  
  font-size: 16px;
}

.Assigned{
    color:#FE8B02;
    font-size: 16px;
}
.Completed{
    color: green;
    font-size: 16px;
}
.In-Process{
    color: #0C344E;
    font-size: 16px;
}
.Cancelled{
    color:#808080;
    font-size: 16px;
}
.On-Hold{
    color:#C24C4C;
    font-size: 16px;
}

