.rdrDefinedRangesWrapper {
    width: 120px;
}
.rdrInputRanges{
    display:"none";
}
.rdrCalendarWrapper {
    width: 75%;
    overflow: hidden;
}
.rdrDateRangePickerWrapper {
    height: 21.5rem;
}