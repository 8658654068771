.errorHanlde {
  color: red;
  font-size: 13px;
  font-style: italic;
}
.activeBc {
  background-color: #d7ffe9;
  color: green;
  padding: 5px 15px;
  border-radius: 5px;
}
.InactiveBc {
  background-color: #eff0f6;
  color: #c24c4c;
  padding: 5px 15px;
  border-radius: 5px;
  background-color: #ffeaea;
}
.makespace {
  white-space: nowrap !important;
}
.HandleEdit {
  /* margin-left: -25px; */
  margin-top: 15px;
}
.labelHanlde label {
  margin-top: -20px !important;
  font-size: small;
}
