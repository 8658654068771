table {
  border-spacing: 0 15px;
}

th {
  background-color: #ffe3d2;
}

th,
td {
  width: 150px;
  text-align: center;
  padding: 5px;
}

.tabsFlow {
  display: flex;
}
.tabs {
  display: flex;
  column-gap: 50px;

  overflow: hidden;
  white-space: nowrap !important;
}
.tabdetail {
  width: auto;
}
.tabtext {
  font-size: 18px;
  cursor: pointer;
}
.tabActive {
  padding: 2px 12px;
  background-color: #0c344e;
  border-radius: 100px;
  margin-left: 6px;
  color: white;
  font-size: 14px;
  transition: all 0.3s;
}
.tabCount {
  padding: 4px 12px;
  background-color: rgb(239, 240, 246);
  border-radius: 100px;
  margin-left: 6px;
  color: #a0a3bd;
  font-size: 14px;
}
.underline {
  width: auto;
  height: 3px;
  background-color: #0c344e;
  margin-top: 10px;
  border-radius: 10px;
}
.tabs::-webkit-scrollbar {
  display: none;
}
